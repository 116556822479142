import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    chatBotHistoryContainer: {
        margin: '20px 20px 0px 20px',
        overflow: 'scroll',
        height: '100%',
        width: '100%',
        paddingBottom: '20px',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '40px'
        }
    },
    chatMessageContainer: {
        padding: '10px 0',
    },
    chatName: {
        fontWeight: 'bold',
        fontSize: '1.3rem',
        margin: 0,
        padding: 0,
    },
    chatMsg: {
        fontSize: '1.2rem',
        margin: 0,
        padding: 0,
    },
    ldsEllipsis: {
        display: 'inline-block',
        position: 'relative',
        width: '80px',
        height: '80px',
        zoom: '0.5',
        MozTransform: 'scale(0.5)',
    },
    InterviewMe: {
        color: '#1f159f',
    },
}));

export default useStyles;