import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '600px',
        alignItems: 'center',
        borderRadius: '20px',
        boxShadow: '0px 3px 8px -2px rgba(0,0,0,0.65)',
        padding: '20px',
        height: '100%',
        maxHeight: '800px',
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none',
        }
    },
    logo: {
        display: 'block',
        width: '100px',
        margin: '20px auto',
        cursor: 'pointer'
    },
    title: {
        textAlign: 'center',
        fontSize: '1.7rem',
        fontWeight: 'bold',
        margin: 0,
        padding: 0,
    },
    desc: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.3rem',
        margin: '10px',
        padding: 0,
    },
    nextBtn: {
        fontSize: '20px',
        borderRadius: '20px',
        color: 'white',
        marginTop: '50px',
        padding: '15px 45px',
        border: 'none',
        cursor: "pointer",
        maxWidth: '300px',
        background: '#1f159f',
    }
}));

export default useStyles;