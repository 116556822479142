import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    chatComposeContainer: {
        width: 'inherit',
        padding: '10px 20px',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
    },
    chatComposeContainerInner: {
        background: '#f4f4f4',
        display: 'flex',
        borderRadius: '10px',
        height: '60px',
        alignItems: 'center',
        boxShadow: '0 8px 16px rgba(61, 61, 61, 0.05)',
    },
    chatComposeTextContainer: {
        flexGrow: 1,
        marginLeft: '20px',
        marginRight: '20px',
    },
    chatComposeInput: {
        background: 'transparent',
        border: 'none',
        outline: 'none',
        width: '100%',
        fontSize: '1.3rem',
        padding: '10px 0',
        position: 'relative',
    },
    sendBtnContainer: {
        marginRight: '20px',
    },
    sendBtn: {
        cursor: 'pointer',
    },
}));

export default useStyles;