import React from 'react';
import mixpanel from 'mixpanel-browser';
import Home from './pages/Home/Home';
import RoleExpQuestion from "./pages/RoleExpQuestion/RoleExpQuestion";
import FrameworkQuestion from "./pages/FrameworkQuestion/FrameworkQuestion";
import PickQuestionType from "./pages/PickQuestionType/PickQuestionType";
import EmailPrompt from "./pages/EmailPrompt/EmailPrompt";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {QuestionDataProvider} from "./contexts/QuestionDataContext";
import ChatInterview from "./pages/ChatInterview/ChatInterview";

mixpanel.init('275def47a099b5ed403fb533b1a4a95a');

function App() {
    return (
        <QuestionDataProvider>
            <div className="App">
                <Router>
                    <Routes>
                        <Route path="/" Component={Home}/>
                        <Route path="/question-1" Component={PickQuestionType}/>
                        <Route path="/question-2" Component={RoleExpQuestion}/>
                        <Route path="/question-3" Component={FrameworkQuestion}/>
                        <Route path="/interview" Component={ChatInterview}/>
                        <Route path="/email" Component={EmailPrompt}/>
                    </Routes>
                </Router>
            </div>
        </QuestionDataProvider>
    );
}

export default App;