import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    logoTextHolder: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer'
    },
    logo: {
        width: '50px',
        position: 'relative',
        left: '-10px'
    },
    headerText: {
        fontSize: '20px',
        display: 'inline',
        userSelect: 'none',
    },
    backIcon: {},
    rootContainer: {
        padding: '10px 20px',
        height: '100%'
    },
    childrenContainer: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    }
}));

export default useStyles;