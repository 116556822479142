import React from "react";
import useStyles from "./ChatMessage.styles";

interface ChatMessageProps {
    from: string;
    message: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({from, message}) => {
    const classes = useStyles();
    return (<div>
        <div className={classes.chatMessageContainer}>
            <div className={`${classes.chatName} ${from == 'You'? classes.you : classes.InterviewMe}`}>
                {from}
            </div>
            <div className={classes.chatMsg}>
                {message}
            </div>
        </div>
    </div>)
};

export default ChatMessage;