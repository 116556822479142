const API_URL = window.location.href.includes('localhost:') ? 'http://localhost:4000/prod': 'https://api.interviewmeai.com/prod';

export const getQuestion = async (questionType: string, role: string, experience: string, framework: string) => {
    const response = await fetch(`${API_URL}/question?` + new URLSearchParams({
        questionType,
        role,
        experience,
        framework
    }));

    return await response.json();
};

export const saveEmail = async (email: string): Promise<void> => {
    const url = `${API_URL}/email`;
    const data = { email };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            // TODO: better handling
            console.log('Failed to save email.', response);
            // throw new Error('Failed to save email.');
        }

        // Email saved successfully
        console.log('Email saved successfully.');
    } catch (error) {
        console.error('Error saving email:', error);
    }
};

export const getAnswerWithFollowup = async (question: string, role: string, experience: string, answer: string): Promise<string | null> => {
    const url = `${API_URL}/answer-with-followup`;
    const data = { question, role, experience, answer };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            // TODO: retry
            console.log('Failed to get response.', response);
            return Promise.resolve(null);
        }

        const responseData = await response.json();
        return responseData.gptResponse;
    } catch (error) {
        console.error('Error getting answer:', error);
        return Promise.resolve(null);
    }
};

export const getFinalResponse = async (q1:string, a1:string, q2:string, a2:string, q3:string, a3:string, role:string, experience:string): Promise<string | null> => {
    const url = `${API_URL}/final-response`;
    const data = { q1, a1, q2, a2, q3, a3, role, experience };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            // TODO: retry
            console.log('Failed to get response.', response);
            return Promise.resolve(null);
        }

        const responseData = await response.json();
        return responseData.gptResponse;
    } catch (error) {
        console.error('Error getting answer:', error);
        return Promise.resolve(null);
    }
};

export const saveFeedback = async (feedback: string): Promise<void> => {
    const url = `${API_URL}/feedback`;
    const data = { feedback };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            // TODO: better handling
            console.log('Failed to save feedback.', response);
            // throw new Error('Failed to save feedback.');
        }

        // Feedback saved successfully
        console.log('Feedback saved successfully.');
    } catch (error) {
        console.error('Error saving feedback:', error);
    }
};
