import useStyles from "./Dialog.styles";
import React, {ReactNode} from 'react';


interface QuestionComponentProps {
    children: ReactNode;
    title: String;
    btnlabel: String;
    onNext: React.MouseEventHandler<HTMLButtonElement>;
    showContinueBtn: boolean;
}

const Dialog: React.FC<QuestionComponentProps> = (
    {
        children,
        title,
        btnlabel,
        onNext,
        showContinueBtn
    }) => {
    const classes = useStyles();

    return <div className={classes.questionContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.childrenContainer}>
            {children}
            {
                showContinueBtn &&
                <button
                    className={classes.nextBtn}
                    onClick={onNext}
                >
                    {btnlabel}
                </button>
            }
        </div>
    </div>;
};

export default Dialog;