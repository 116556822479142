import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { ReactNode } from 'react';
import useStyles from "./PageWrapper.styles";
import Dialog from "../Dialog/Dialog";
import { useNavigate } from 'react-router-dom';
import logoSmall from "../../assets/logo-small.png";

interface WrapperComponentProps {
    children: ReactNode;
    title: String;
    btnlabel?: String;
    onNext: React.MouseEventHandler<HTMLButtonElement>;
    showContinueBtn?: boolean;
}

const PageWrapper:React.FC<WrapperComponentProps> = (
    {
        children,
        title,
        btnlabel = 'Continue',
        onNext,
        showContinueBtn = true
    }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const onBack: React.MouseEventHandler<any> = () => {
        navigate(-1);
    };

    return <div className={classes.rootContainer}>
        <div className={classes.header}>
            <div className={classes.logoTextHolder} onClick={onBack}>
                <ArrowBackIosIcon  className={classes.backIcon} fontSize={"large"}/>
                <img className={classes.logo} src={logoSmall} />
            </div>
        </div>
        <div className={classes.childrenContainer}>
            <Dialog
                title={title}
                onNext={onNext}
                showContinueBtn={showContinueBtn}
                btnlabel={btnlabel}
            >
                {children}
            </Dialog>
        </div>
    </div>;
};

export default PageWrapper;