import PageWrapper from "../../components/PageWrapper/PageWrapper";
import React, {useContext, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {TextField} from "@material-ui/core";
import useStyles from "./EmailPrompt.styles";
import {QuestionDataContext} from "../../contexts/QuestionDataContext";
import {saveEmail} from "../../API";
import trackEvent from "../../utils/Telemetry";

const EmailPrompt = () => {
    const classes = useStyles();
    const {data, updateData} = useContext(QuestionDataContext);
    const [email, setEmail] = React.useState('');
    const navigate = useNavigate();
    const onNext: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        if (email.length < 0) return;
        saveEmail(email);
        updateData('email', email);
        trackEvent('Input email', {email});
        navigate('/feedback')
    };
    const onSkip: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        trackEvent('Skip email');
        navigate('/feedback')
    };

    useEffect(() => {
        trackEvent('View email page');
    }, []);

    return <PageWrapper
        title={'Provide your email'}
        onNext={onNext}
        showContinueBtn={false}
    >
        <div className={classes.centerChildren}>
            <div className={classes.instructions}>Please provide your email so we can provide your feedback.</div>
            <TextField
                fullWidth
                label="Email"
                multiline
                minRows={1}
                maxRows={1}
                placeholder={"Type your email here"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="filled"
            />
            <div
                className={classes.centerChildren}
            >
                <button
                    className={classes.nextBtn+' '+(email.length == 0 ? classes.nextBtnDisabled: '')}
                    onClick={onNext}
                    disabled={email.length === 0}
                >
                    {email.length > 0? 'Continue': 'Provide email'}
                </button>
            </div>

        </div>
    </PageWrapper>;
};

export default EmailPrompt;