import React, { createContext, useState, useEffect } from 'react';

interface Data {
    questionType: string;
    question: string;
    role: string;
    experience: string;
    framework: string;
    answer: string;
    followupQuestion1: string;
    followupAnswer1: string;
    followupQuestion2: string;
    followupAnswer2: string;
    finalResponse: string;
    email: string;
}

interface QuestionDataContextProps {
    data: Data;
    updateData: (field: keyof Data, value: string | number) => void;
}

const initialData: Data = {
    questionType: 'non-coding technical question',
    question: '',
    role: 'frontend',
    experience: 'junior',
    framework: 'React',
    answer: '',
    followupQuestion1: '',
    followupAnswer1: '',
    followupQuestion2: '',
    followupAnswer2: '',
    finalResponse: '',
    email: '',
};

const QuestionDataContext = createContext<QuestionDataContextProps>({
    data: initialData,
    updateData: () => {},
});

const QuestionDataProvider: React.FC = ({ children }) => {
    const [data, setData] = useState<Data>(() => {
        const localData = localStorage.getItem('questionData');
        return localData ? JSON.parse(localData) : initialData;
    });

    const updateData = (field: keyof Data, value: string | number) => {
        setData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    useEffect(() => {
        localStorage.setItem('questionData', JSON.stringify(data));
    }, [data]);

    return (
        <QuestionDataContext.Provider value={{data, updateData}}>
            {children}
        </QuestionDataContext.Provider>
    );
};

export { QuestionDataContext, QuestionDataProvider };