import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    chatMessageContainer: {
        padding: '10px 0',
    },
    chatName: {
        fontWeight: 'bold',
        fontSize: '1.3rem',
        margin: 0,
        padding: 0,
    },
    you: {
        color: 'rgb(79, 78, 78)',
    },
    InterviewMe: {
        color: '#1f159f',
    },
    chatMsg: {
        fontSize: '1.2rem',
        margin: 0,
        padding: 0,
    },
}));

export default useStyles;