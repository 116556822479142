import ChatHistory from "./ChatHistory";
import roosterLogo from '../../assets/logo-medium.png';
import ChatCompose from "./ChatCompose";
import React, {useContext, useState} from "react";
import {MessageArray} from "../../interfaces/MessageArray";
import useStyles from "./ChatBot.styles";
import {QuestionDataContext} from "../../contexts/QuestionDataContext";
import {getAnswerWithFollowup, getFinalResponse, saveFeedback} from "../../API";
import trackEvent from "../../utils/Telemetry";
import {useNavigate} from "react-router-dom";

const ChatBot = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {data, updateData} = useContext(QuestionDataContext);
    const [messages, setMessages] = useState<MessageArray>([{
        from: 'InterviewMe',
        message: data.question
    }]);
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);
    const [inputMessage, setInputMessage] = useState<string>('');

    const onNext:React.MouseEventHandler<HTMLButtonElement> = (e) => {
        trackEvent('Click restart');
        navigate('/?restart=true');
    };

    const addMessage = (from: string, message: string) => {
        const newMessage = { from, message};
        setMessages(messages => [...messages, newMessage]);
    };

    const onNewMessage = async (message: string) => {
        if (loading) {
            return;
        }
        setLoading(true);
        addMessage('You', message);
        trackEvent('Send message', {message});
        setInputMessage('');

        const numFollowUps = messages.reduce((accumulator, item) => {
            if (item.from === 'You') {
                return accumulator + 1;
            }
            return accumulator;
        }, 0);
        const needFollowup = numFollowUps < 2;
        const needFinalResponse = numFollowUps === 2;
        const isFeedback = numFollowUps === 3;
        let response;

        if (needFollowup) {
            const messagesFromInterviewMe = messages.filter(msg => msg.from === 'InterviewMe');
            const lastMessage = messagesFromInterviewMe[messagesFromInterviewMe.length - 1].message;

            response = (await getAnswerWithFollowup(lastMessage, data.role, data.experience, message));
        } else if(needFinalResponse) {
            // get feedback
            response = (await getFinalResponse(
                data.question, data.answer, data.followupQuestion1, data.followupAnswer1, data.followupQuestion2,
                message, data.role, data.experience
            ));
        } else if (isFeedback) {
            saveFeedback(message);
            setDone(true);
        }

        setLoading(false);

        if (!response) {
            setInputMessage(message); // reset input message
            // remove last message
            setMessages(messages => messages.slice(0, messages.length - 1));
            return;
        }

        addMessage('InterviewMe', response);
        trackEvent('Receive message', {message: response});

        if (numFollowUps === 0) { // first
            updateData('answer', message);
            updateData('followupQuestion1', response);
        } else if (numFollowUps === 1) { // second
            updateData('followupAnswer1', message);
            updateData('followupQuestion2', response);
        } else if (numFollowUps === 2) { // feedback on interview
            updateData('followupAnswer2', message);
            addMessage('InterviewMe', 'If you wouldn\'t mind, please share feedback on your experience with InterviewMe AI. This will help us improve our product. And feel free to leave your email. Thank you!');
        } else { // feedback from user
            setDone(true);
            trackEvent('Interview complete');
        }
    };

    return (
    <div className={classes.mainContainer}>
        <div>
            <img className={classes.logo} src={roosterLogo}/>
            <div className={classes.title}>InterviewMe AI</div>
            <div className={classes.desc}>Mock Interview</div>
        </div>
        <ChatHistory
            onSelectPrompt={onNewMessage.bind(this)}
            messages={messages}
            isLoading={loading}
        />
        {!done && <ChatCompose onNewMessage={onNewMessage} isLoading={loading} initialMessage={inputMessage} />}
        {done && <button
            className={classes.nextBtn}
            onClick={onNext}
        >
            Try again
        </button>}
    </div>)
};

export default ChatBot;