import {TextField} from "@material-ui/core";
import React, {useContext, useEffect} from "react";
import { useNavigate } from 'react-router-dom';

import PageWrapper from "../../components/PageWrapper/PageWrapper";

import useStyles from "./FrameworkQuestion.styles";
import {QuestionDataContext} from "../../contexts/QuestionDataContext";
import trackEvent from "../../utils/Telemetry";

const FrameworkQuestion = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { data, updateData } = useContext(QuestionDataContext);
    const [framework, setFramework] = React.useState(data.framework);
    const onNext:React.MouseEventHandler<HTMLButtonElement> = (e) => {
        updateData('framework', framework);
        updateData('question', '');
        trackEvent('Pick framework', { framework });
        navigate('/interview')
    };
    const onFrameworkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFramework(e.target.value);
    };

    useEffect(() => {
        trackEvent('View framework question page');
    }, []);

    return <PageWrapper
        title={'What framework do you want to focus on?'}
        onNext={onNext}
    >
        <div className={classes.instruction}>You can type whatever framework you want below.</div>
        <TextField
            fullWidth
            label="Framework"
            variant="outlined"
            value={framework}
            onChange={onFrameworkChange}
        />

    </PageWrapper>;
};

export default FrameworkQuestion;