import React, {useContext, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {QuestionDataContext} from "../../contexts/QuestionDataContext";
import trackEvent from "../../utils/Telemetry";

const PickQuestionType = () => {
    const navigate = useNavigate();
    const { data, updateData } = useContext(QuestionDataContext);
    const [questionType, setQuestionType] = React.useState(data.questionType);
    const onNext:React.MouseEventHandler<HTMLButtonElement> = (e) => {
        updateData('questionType', questionType);
        trackEvent('Pick question type', { questionType });
        navigate('/question-2');
    };

    useEffect(() => {
        trackEvent('View pick question type page');
    }, []);

    return <PageWrapper
        title={'What question type do you want to focus on?'}
        onNext={onNext}
    >
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Question type</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="trivia"
                value={questionType}
                onChange={(e) => setQuestionType(e.target.value)}
                name="question-type-radio-buttons-group"
            >
                <FormControlLabel value="non-coding technical question" control={<Radio />} label="Trivia" />
                <FormControlLabel value="coding" control={<Radio />} label="Coding" />
                <FormControlLabel value="soft skills" control={<Radio />} label="Soft skills" />
            </RadioGroup>
        </FormControl>
    </PageWrapper>;
};

export default PickQuestionType;