import React, { useEffect } from 'react';
import sendIcon from '../../assets/send_black_24dp.svg';
import { useState, KeyboardEvent } from "react";
import useStyles from "./ChatCompose.styles";

interface ChatComposeProps {
    onNewMessage: Function;
    isLoading: boolean;
    initialMessage?: string;
}

const ChatCompose: React.FC<ChatComposeProps> = ({onNewMessage, isLoading, initialMessage}) => {
    const classes = useStyles();
    const [message, setMessage] = useState<string>(initialMessage || '');

    // use effect hook to update the message when the initial message changes
    useEffect(() => {
        if (initialMessage) {
            setMessage(initialMessage);
        }
    }, [initialMessage]);

    const submitMessage = () => {
        if (message.trim().length > 0 && !isLoading) {
            onNewMessage(message);
            setMessage('');
        }
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            submitMessage();
        }
    }

    return <div className={classes.chatComposeContainer}>
        <div className={classes.chatComposeContainerInner}>
            <div className={classes.chatComposeTextContainer}>
                <input
                    type={'text'}
                    value={message}
                    maxLength={4000}
                    onKeyDown={onKeyDown}
                    onChange={(e) => {setMessage(e.target.value);}}
                    className={classes.chatComposeInput}
                    placeholder={'Answer here'}
                />
            </div>
            <div className={classes.sendBtnContainer}>
                <img
                    onClick={submitMessage}
                    className={classes.sendBtn}
                    src={sendIcon}
                />
            </div>
        </div>

    </div>;
};

export default ChatCompose;
