import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appName: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '20px',
        userSelect: 'none',
        marginLeft: '5px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    logoTextHolder: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
    },
    logo: {
        width: '50px',
    },
    title: {
        fontSize: '50px',
        color: 'white',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        marginLeft: '20px',
        marginRight: '20px',
    },
    subtitle: {
        fontSize: '28px',
        color: 'white',
        textAlign: 'center',
        marginLeft: '20px',
        marginRight: '20px',
    },
    aboveTheFoldContainer: {
        background: '#0b3b2c',
        backgroundImage: "url('/bg.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    ctaSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '80px',
        paddingBottom: '280px',
    },
    ctaBtn: {
        fontSize: '30px',
        borderRadius: '20px',
        backgroundColor: 'white',
        color: '#041711',
        marginTop: '50px',
        padding: '15px 45px',
        border: 'none',
        cursor: "pointer"
    },
    explanationContainer: {
        display: 'flex',
    },
    explanationSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        padding: '10px 20px'
    },
    belowTheFoldContainer : {
        maxWidth: '850px',
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '0px 3px 8px -2px rgba(0,0,0,0.65)',
        margin: 'auto',
        position: 'relative',
        top: '-180px',
        padding: '30px 20px 80px 20px'
    },
    howItWorksText: {
        fontSize: '30px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '60px'
    },
    explanationText: {
        textAlign: 'center',
        fontSize: '18px',
        marginTop: '20px',
        lineHeight: '25px'
    },
    explanationImg: {
        width: '60px'
    },
    videoContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px',
        top: '-180px'
    },
    videoInnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '900px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '600px',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '440px',
        }
    },
    video: {
        width: '100%',
        maxWidth: '660px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '440px',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '285px',
        }
    },
    macbook: {
        width: '100%'
    }
}));

export default useStyles;