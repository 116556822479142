import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    questionContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '600px',
        alignItems: 'center',
        borderRadius: '20px',
        boxShadow: '0px 3px 8px -2px rgba(0,0,0,0.65)',
        padding: '20px',

    },
    childrenContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '500px',
        overflowY: 'auto',
    },
    title: {
        fontSize: '30px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '50px'
    },
    nextBtn: {
        fontSize: '20px',
        borderRadius: '20px',
        color: 'white',
        marginTop: '50px',
        padding: '15px 45px',
        border: 'none',
        cursor: "pointer",
        maxWidth: '300px',
        background: '#1f159f',
    }
}));

export default useStyles;