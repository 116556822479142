import React, {useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";

import useStyles from "./Home.styles";
import question from "../../assets/question-feedback.svg";
import logoSmall from "../../assets/logo-small.png";
import roleStackExperience from "../../assets/role-stack-experience.svg";
import triviaCodingSoft from "../../assets/trivia-coding-soft-skills.svg";
import macbook from "../../assets/macbook.png";
import trackEvent from "../../utils/Telemetry";

const Home = () => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const restart = queryParams.get('restart');

    useEffect(() => {
        if (restart) {
            navigate('/');
            navigate('/question-1');
        }

        trackEvent('View home');
    }, []);

    return (<div>
        <div className={classes.aboveTheFoldContainer}>
            <div className={classes.header}>
                <div className={classes.logoTextHolder}>
                    <img className={classes.logo} src={logoSmall} />
                    <div className={classes.appName}>InterviewMe AI</div>
                </div>
            </div>
            <div className={classes.ctaSection}>
                <div className={classes.title}>Practice interviewing with an AI</div>
                <div className={classes.subtitle}>For Software Engineers at any level</div>
                <button
                    className={classes.ctaBtn}
                    onClick={() => navigate('/question-1')}
                >
                    Start
                </button>
            </div>
        </div>
        <div className={classes.belowTheFoldContainer}>
            <div className={classes.howItWorksText}>How it works</div>
            <div className={classes.explanationContainer}>
                <div className={classes.explanationSection}>
                    <img className={classes.explanationImg} src={roleStackExperience} />
                    <div className={classes.explanationText}>
                        Pick role, tech stack, and experience.
                    </div>
                </div>
                <div className={classes.explanationSection}>
                    <img className={classes.explanationImg} src={triviaCodingSoft} />
                    <div className={classes.explanationText}>
                        Pick trivia, coding, or soft skills.
                    </div>
                </div>
                <div className={classes.explanationSection}>
                    <img className={classes.explanationImg} src={question} />
                    <div className={classes.explanationText}>
                        Answer the question and get immediate feedback from an expert AI.
                    </div>
                </div>
            </div>
        </div>
        <div className={classes.videoContainer}>
            <div className={classes.videoInnerContainer}>
                <img className={classes.macbook} src={macbook} />
                <video className={classes.video} loop autoPlay muted playsInline>
                    <source src="/demo.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    </div>);
};

export default Home;