import React from "react";
import ChatMessage from "./ChatMessage";
import {MessageArray} from "../../interfaces/MessageArray";
import {RefObject, useEffect, useRef} from "react";
import useStyles from "./ChatHistory.styles";

interface ChatHistoryProps {
    messages: MessageArray,
    onSelectPrompt: Function,
    isLoading: Boolean
}

const ChatHistory: React.FC<ChatHistoryProps> = (
    {
        messages,
        onSelectPrompt,
        isLoading
    }) => {
    const bottomRef: RefObject<HTMLDivElement> = useRef(null);
    const classes = useStyles();

    const getMessages = (): React.ReactNode[] => {
        return messages.map((item, index) => {
            return <ChatMessage key={index} from={item.from} message={item.message}/>
        });
    }

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [messages]);

    return <div className={classes.chatBotHistoryContainer}>
        {getMessages()}
        {
            isLoading &&
            <div className={classes.chatMessageContainer}>
                <div className={`${classes.chatName} ${classes.InterviewMe}`}>
                    InterviewMe AI
                </div>
                <div className={classes.chatMsg}>
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        }
        <div ref={bottomRef}/>
    </div>;
};

export default ChatHistory;