import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    loadingIcon: {
        padding: '100px'
    },
    loadingHolder: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
}));

export default useStyles;