import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    instructions: {
        marginBottom: '20px',
        fontSize: '20px',
        textAlign: 'left',
        flexGrow: 1,
        width: '100%'
    },
    nextBtn: {
        fontSize: '20px',
        borderRadius: '20px',
        color: 'white',
        marginTop: '50px',
        padding: '15px 45px',
        border: 'none',
        cursor: "pointer",
        maxWidth: '300px',
        background: '#1f159f'
    },
    centerChildren: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    nextBtnDisabled: {
        cursor: 'default',
        background: '#919191',
    },
}));

export default useStyles;