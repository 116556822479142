import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    instruction: {
        marginBottom: '15px',
        fontSize: '16px',
    }
}));

export default useStyles;