import React, {ChangeEvent, useContext, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {QuestionDataContext} from "../../contexts/QuestionDataContext";
import trackEvent from "../../utils/Telemetry";

const SoftwareEngineerLevels = [
    { value: 'junior', label: 'Junior' },
    { value: 'mid level', label: 'Mid-Level' },
    { value: 'senior', label: 'Senior' },
    { value: 'staff', label: 'Staff' },
    { value: 'senior staff', label: 'Senior Staff' },
];

const RoleExpQuestion = () => {
    const navigate = useNavigate();
    const { data, updateData } = useContext(QuestionDataContext);
    const [role, setRole] = React.useState(data.role);
    const [exp, setExp] = React.useState(data.experience);
    const onNext:React.MouseEventHandler<HTMLButtonElement> = (e) => {
        updateData('role', role);
        updateData('experience', exp);
        trackEvent('pick role and experience page', {role, exp});

        if (role === 'frontend') {
            updateData('framework', 'React');
        } else {
            updateData('framework', 'Node.js');
        }

        // no need to ask for specific framework if focusing on soft skills
        if (data.questionType === 'soft-skills') {
            navigate('/interview');
        } else {
            navigate('/question-3');
        }
    };
    const handleRoleChange = (event: ChangeEvent<{ name?: string | undefined; value: string | unknown; }>) => {
        const selectedValue: string = event.target.value as string || 'frontend';
        setRole(selectedValue);
    };
    const handleExpChange = (event: ChangeEvent<{ name?: string | undefined; value: string | unknown; }>) => {
        const selectedValue: string = event.target.value as string || 'junior';
        setExp(selectedValue);
    };

    useEffect(() => {
        trackEvent('View role and experience page');
    }, []);

    return <PageWrapper
        title={'What would you like to interview for?'}
        onNext={onNext}
    >
        <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
                labelId="role-select-label"
                value={role}
                label="Role"
                onChange={handleRoleChange}
            >
                <MenuItem value={"backend"}>Backend</MenuItem>
                <MenuItem value={"frontend"}>Frontend</MenuItem>
            </Select>
        </FormControl>
        <FormControl fullWidth>
            <InputLabel>Experience</InputLabel>
            <Select
                labelId="experience-select-label"
                value={exp}
                label="Experience"
                onChange={handleExpChange}
            >
                {SoftwareEngineerLevels.map((level) => (
                    <MenuItem key={level.value} value={level.value}>
                        {level.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </PageWrapper>;
};

export default RoleExpQuestion;