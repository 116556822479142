import React, {useContext, useEffect} from "react";
import useStyles from "./ChatInterview.styles";
import {getQuestion} from "../../API";
import {QuestionDataContext} from "../../contexts/QuestionDataContext";
import trackEvent from "../../utils/Telemetry";
import ChatBot from "../../components/Chat/ChatBot";
import CircularProgress from "@mui/material/CircularProgress";

const ChatInterview = () => {
    const classes = useStyles();
    const {data, updateData} = useContext(QuestionDataContext);
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        trackEvent('View chat page');
        if (data.question.length > 0) {
            setLoaded(true);
            return;
        }
        const fetchData = async () => {
            const {questionType, role, experience, framework} = data;
            const {question} = await getQuestion(questionType, role, experience, framework);

            updateData('question', question);
            setLoaded(true);
            trackEvent('Get question', {question});
        };

        fetchData();
    }, []);

    return <div className={classes.mainContainer}>
        {!loaded && <div className={classes.loadingHolder}>This can take a few seconds...<CircularProgress className={classes.loadingIcon}/></div>}
        {loaded && <ChatBot />}
    </div>
};

export default ChatInterview;